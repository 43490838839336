import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
  Button,
  Row,
  Col,
  Form,
  ListGroup,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { getProfile, profileAction } from "../../../store/actions/AuthActions";

import messageTypeList from "../../../utils/message-type";

import { CSVLink } from "react-csv";

import DTable from "../../components/Table";
import { batch } from "react-redux";
import { be } from "date-fns/locale";

import Select from "react-select";

import stateData from "../../../utils/states.json";

import PhoneInput from "react-phone-number-input";

//const Orders = () => {
const Messages = ({ props, history }) => {
  const dispatch = useDispatch();

  const [devices, setDevices] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [loading, setLoading] = useState(false);
  const csvInstance = useRef(null);

  const profile = useSelector((state) => state.auth.profile);

  const [activePage, setActivePage] = useState(0);
  let errorsObj = { instance: "", phone: "", message: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [xlsData, setXlsData] = useState([]);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  //modal
  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [activeDevice, setActiveDevice] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [sNo, setSNo] = useState("");
  const [imei, setImei] = useState("");
  const [asn11, setAsn11] = useState("");
  const [simcardNo, setSimcardNo] = useState("");
  const [url, setUrl] = useState(true);
  const [cId, setCId] = useState(0);
  const [password, setPassword] = useState("");
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [a, setA] = useState(0);
  const [b, setB] = useState(0);
  const [hp, setHp] = useState(0);
  const [pumpDepth, setPumpDepth] = useState(0);

  const [custName, setCustName] = useState("");
  const [custNumber, setCustNumber] = useState("");

  const [permission, setPermission] = useState([
    {
      value: 0,
      label: "All Employees",
    },
  ]);
  const [employees, setEmployees] = useState([]);

  const [state, setState] = useState({
    value: "select",
    label: "Select State",
  });
  const [district, setDistrict] = useState({
    value: "select",
    label: "Select District",
  });
  const [village, setVillage] = useState("");

  //search
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [selectedCustomerId, setSelectedCustomerId] = useState({
    value: "select",
    label: "Select Customer",
  });

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadDevices();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    if (
      xlsData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setXlsData([]);
      });
    }
  }, [xlsData]);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadDevices();
  }, [page, pageSize]);

  useEffect(() => {
    if (hp != "" && pumpDepth != "") {
      const power = hp * 746;
      const lpm = (hp * 950 * 950) / pumpDepth / 6.5 / 60;
      setA(((hp * 100000) / lpm).toFixed(2));
    } else {
      setA(0);
    }
  }, [hp, pumpDepth]);

  // Data Table End

  useEffect(() => {
    loadProfile();
    dispatch(getProfile());
    loadDevices();
    //loadCustomers();
    loadEmployee();
  }, [dispatch]);

  useEffect(() => {
    if (!showDeviceModal) {
      resetForm();
    }
  }, [showDeviceModal]);

  useEffect(() => {
    if (devices) {
      setLoading(false);
    }
  }, [devices]);

  async function loadProfile() {
    try {
      const { data } = await axiosInstance.get("company/profile");

      console.log("profile data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        //setUser(data.user);
        dispatch(profileAction(data.profile));
        console.log("profile data - " + JSON.stringify(data));
      }
    } catch (error) {}
  }

  const loadEmployee = async () => {
    const { data } = await axiosInstance.post("company/users", {
      role: 3,
      page: 0,
      limit: 99999999,
      search: "",
    });

    if (data.status) {
      setEmployees(data.users);
    } else {
      setEmployees([]);
    }
  };

  const loadDevices = async () => {
    const { data } = await axiosInstance.post("company/devices", {
      PAGE: page,
      LIMIT: pageSize,
      SEARCH: search,
    });

    if (data.status) {
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
      setDevices(data.devices);
    }
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const initXlsData = async () => {
    var dummy = [];

    const { data } = await axiosInstance.post("company/devices");

    if (data.status) {
      console.log("total messages - " + data.devices.length);
      await asyncForEach(data.devices, async (d, i) => {
        //devices.map((data, i) => {
        dummy.push({
          NO: i + 1,
          S_NO: d.S_NO,
          MQTT_EN: d.MQTT_EN,
          IMEI: d.IMEI,
          ASN_11: d.ASN_11,
          URL: d.URL,
          C_ID: d.C_ID,
          PASSWORD: d.PASSWORD,
          LOCATION_EN: d.LOCATION_EN,
          LAT: d.LAT,
          LONG: d.LONG,
          LBS_URL: d.LBS_URL,
          LPM_CONST_EN: d.LPM_CONST_EN,
          A: d.A,
          B: d.B,
          CREATED_AT: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
        });
      });

      setXlsData(dummy);
    }
  };

  const customerName = (companyId) => {
    const company = employees.find((c) => c.id === companyId);
    if (company) {
      return company.id + " - " + company.name + "(" + company.phone + ")";
    } else {
      return "No";
    }
  };

  const columns = [
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false m-0 p-2">
              <svg width="15px" height="15px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {getRole() == 2 ||
              (getRole() == 3 && (profile.device_edit ?? false)) ? (
                <Dropdown.Item as="button" onClick={(e) => onDeviceEdit(d)}>
                  Edit
                </Dropdown.Item>
              ) : (
                <></>
              )}

              {getRole() == 2 ||
              (getRole() == 3 && (profile.device_delete ?? false)) ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => {
                    swal({
                      title: "Are you sure?",
                      text: "Once deleted, you will not be able to recover this device!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        onDeviceDelete(d);
                      }
                    });
                  }}
                >
                  Delete
                </Dropdown.Item>
              ) : (
                <></>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },

    {
      Header: "No",
      accessor: "",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "S_NO",
      accessor: "S_NO",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return <Link to={"/device/"+d.id} >{d.S_NO}</Link>;
        
      },
    },

    {
      Header: "IMEI",
      accessor: "IMEI",
      sort: false,
    },
    {
      Header: "ASN_11",
      accessor: "ASN_11",
      sort: false,
    },

    {
      Header: "SIMCARD_NO",
      accessor: "SIMCARD_NO",
      sort: false,
    },

    {
      Header: "URL",
      accessor: "URL",
      sort: false,
    },

    {
      Header: "C_ID",
      accessor: "C_ID",
      sort: false,
    },

    {
      Header: "PASSWORD",
      accessor: "PASSWORD",
      sort: false,
    },

    // {
    //   Header: "LOCATION_EN",
    //   accessor: "LOCATION_EN",
    //   sort: false,
    // },

    {
      Header: "LAT",
      accessor: "LAT",
      sort: false,
    },

    {
      Header: "LONG",
      accessor: "LONG",
      sort: false,
    },

    // {
    //   Header: "LBS_URL",
    //   accessor: "LBS_URL",
    //   sort: false,
    // },

    // {
    //   Header: "LPM_CONST_EN",
    //   accessor: "LPM_CONST_EN",
    //   sort: false,
    // },

    {
      Header: "HP",
      accessor: "HP",
      sort: false,
    },

    {
      Header: "PUMP_DEPTH",
      accessor: "PUMP_DEPTH",
      sort: false,
    },

    // {
    //   Header: "A",
    //   accessor: "A",
    //   sort: false,
    // },

    // {
    //   Header: "B",
    //   accessor: "B",
    //   sort: false,
    // },

    {
      Header: "STATE",
      accessor: "STATE",
      sort: false,
    },

    {
      Header: "DISTRICT",
      accessor: "DISTRICT",
      sort: false,
    },
    {
      Header: "VILLAGE",
      accessor: "VILLAGE",
      sort: false,
    },

    {
      Header: "CUSTOMER_NAME",
      accessor: "CUSTOMER_NAME",
      sort: false,
    },

    {
      Header: "CUSTOMER_PHONE",
      accessor: "CUSTOMER_PHONE",
      sort: false,
    },

    {
      Header: "CREATED_AT",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },
  ];

  const onDeviceSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (!isEdit && sNo === "") {
      error = true;
      errorObj.sNo = "S_NO is Required";
    }

    // if (asn11 === "") {
    //   error = true;
    //   errorObj.asn11 = "ASN_11 is Required";
    // }

    if (imei === "") {
      error = true;
      errorObj.imei = "IMEI is Required";
    }

    // if (url === "") {
    //   error = true;
    //   errorObj.url = "URL is Required";
    // }

    // if (cId === "") {
    //   error = true;
    //   errorObj.cId = "C_ID is Required";
    // }

    // if (password === "") {
    //   error = true;
    //   errorObj.password = "PASSWORD is Required";
    // }

    if (state.value === "select") {
      error = true;
      errorObj.state = "State is required";
    }

    if (!permission && getRole() == 2) {
      error = true;
      errorObj.permission = "Permission is required";
    }

    // if (district.value === "select") {
    //   error = true;
    //   errorObj.district = "District is required";
    // }

    // if (village === "") {
    //   error = true;
    //   errorObj.village = "Village is required";
    // }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          `company/${isEdit ? "update" : "add"}-device`,
          {
            S_NO: isEdit ? activeDevice.S_NO : sNo,
            MQTT_EN: password != "",
            ASN_11: asn11,
            IMEI: imei,
            URL: url,
            C_ID: cId,
            PASSWORD: password,
            LOCATION_EN: lat != "" && long != "",
            LAT: lat,
            LONG: long,
            //LBS_URL: "",
            LPM_CONST_EN: hp != "" && pumpDepth != null,
            HP: hp,
            PUMP_DEPTH: pumpDepth,
            A: a,
            B: b,
            // CUSTOMER_ID:
            // selectedCustomerId.value == "select" ? 0 : selectedCustomerId.value,
            STATE: state.value,
            DISTRICT: district.value,
            VILLAGE: village,

            SIMCARD_NO: simcardNo,
            CUSTOMER_NAME: custName,
            CUSTOMER_PHONE: custNumber,
            PERMISSIONS: getRole() == 2 ? permission.map((x) => x.value) : null,
          }
        );

        if (data.status === false) {
          //toast.error(data.message);
          swal(`${isEdit ? "Update" : "Add"} Device`, data.message, "error");
        } else {
          swal(`${isEdit ? "Update" : "Add"} Device`, data.message, "success");
          resetForm();

          setTimeout(() => {
            loadDevices();
          }, 500);
        }
      } catch (error) {
        swal(`${isEdit ? "Update" : "Add"} Device`, error.message, "error");
      }
    }
  };

  const onDeviceEdit = async (device) => {
    setActiveDevice(device);
    setIsEdit(true);

    setSNo(device.S_NO);
    setAsn11(device.ASN_11);
    setImei(device.IMEI);
    setLat(device.LAT);
    setLong(device.LONG);
    setUrl(device.URL);
    setCId(device.C_ID);
    setPassword(device.PASSWORD);

    setHp(device.HP);
    setPumpDepth(device.PUMP_DEPTH);

    setA(device.A);
    setB(device.B);

    setCustName(device.CUSTOMER_NAME);
    setCustNumber(device.CUSTOMER_PHONE);
    setSimcardNo(device.SIMCARD_NO);

    if (device.STATE != "") {
      setState({
        value: device.STATE,
        label: device.STATE,
      });
    }

    if (device.DISTRICT != "") {
      setDistrict({
        value: device.DISTRICT,
        label: device.DISTRICT,
      });
    }

    setVillage(device.VILLAGE ?? "");

    // const cName2 = customerName(device.CUSTOMER_ID);
    // if (cName2 == "No") {
    //   setSelectedCustomerId({ value: "select", label: "Select Customer" });
    // } else {
    //   setSelectedCustomerId({ value: device.CUSTOMER_ID, label: cName2 });
    // }

    if (device.PERMISSIONS && getRole() == 2) {
      var data = [];
      device.PERMISSIONS.map((x) => {
        const name = customerName(x);

        data.push({
          value: x,
          label: x == 0 ? "All Emplyees" : name,
        });
      });

      setPermission(data);
    }

    setShowDeviceModal(true);
  };

  const onDeviceDelete = async (device) => {
    try {
      const { data } = await axiosInstance.post(`company/remove-device`, {
        S_NO: device.S_NO,
      });

      if (data.status === false) {
        //toast.error(data.message);
        swal(`Remove Device`, data.message, "error");
      } else {
        swal(`Remove Device`, data.message, "success");
        resetForm();

        setTimeout(() => {
          loadDevices();
        }, 500);
      }
    } catch (error) {
      swal(`Remove Device`, error.message, "error");
    }
  };

  const resetForm = async () => {
    setSNo("");
    setAsn11("");
    setImei("");

    setLat(0);
    setLong(0);
    setUrl("");
    setCId(0);
    setPassword("");

    setA(0);
    setB(0);
    setHp(0);
    setPumpDepth(0);

    setCustName("");
    setCustNumber("");
    setSimcardNo("");

    // setSelectedCustomerId({
    //   value:"select",
    //   label:"Select Customer"
    // });

    setState({
      value: "select",
      label: "Select State",
    });

    setDistrict({
      value: "select",
      label: "Select Disctict",
    });

    setIsEdit(false);
    setActiveDevice(null);
    setShowDeviceModal(false);
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: 0,
      background: "#fff",
      border: "1px solid #6e6e6e",
      color: "#6e6e6e",
      height: "56px",
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    //   return {
    //     ...styles,
    //     backgroundColor: isDisabled ? 'red' : blue,
    //     color: '#FFF',
    //     cursor: isDisabled ? 'not-allowed' : 'default',
    //   };
    // },
  };

  const getRole = () => {
    return profile ? profile.role : 0;
  };

  return (
    <>
      <Modal
        size={"xl"}
        className="modal fade"
        show={showDeviceModal}
        onHide={setShowDeviceModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onDeviceSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  {!isEdit ? "Add" : "Update"} Device
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowDeviceModal(false)}
                >
                  <span>×</span>
                </button>
              </div>

              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-row mb-3">
                      <div className="form-group col-md-3">
                        <label className="required-field">S_NO</label>
                        <input
                          type="text"
                          value={sNo}
                          onChange={(e) => setSNo(e.target.value)}
                          placeholder="S_NO"
                          className="form-control"
                          disabled={isEdit}
                        />
                        {errors.sNo && (
                          <div className="text-danger fs-12">{errors.sNo}</div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label className="required-field">IMEI</label>
                        <input
                          type="text"
                          value={imei}
                          onChange={(e) => setImei(e.target.value)}
                          placeholder="IMEI Number"
                          className="form-control"
                        />
                        {errors.imei && (
                          <div className="text-danger fs-12">{errors.imei}</div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label className="">ASN_11</label>
                        <input
                          type="text"
                          value={asn11}
                          onChange={(e) => setAsn11(e.target.value)}
                          placeholder="ASN_11 Number"
                          className="form-control"
                        />
                        {errors.asn11 && (
                          <div className="text-danger fs-12">
                            {errors.asn11}
                          </div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label className="">SIM CARD NO</label>
                        <input
                          type="text"
                          value={simcardNo}
                          onChange={(e) => setSimcardNo(e.target.value)}
                          placeholder="Sim Card Number"
                          className="form-control"
                        />
                        {errors.simcardNo && (
                          <div className="text-danger fs-12">
                            {errors.simcardNo}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-row mb-3">
                      <div className="form-group col-md-3">
                        <label className="">URL</label>
                        <input
                          type="text"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          placeholder="URL"
                          className="form-control"
                        />
                        {errors.url && (
                          <div className="text-danger fs-12">{errors.url}</div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label className="">C_ID</label>
                        <input
                          type="number"
                          value={cId}
                          onChange={(e) => setCId(e.target.value)}
                          placeholder="C_ID"
                          className="form-control"
                        />
                        {errors.cId && (
                          <div className="text-danger fs-12">{errors.cId}</div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label className="">PASSWORD</label>
                        <input
                          type="text"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          className="form-control bi-eye"
                        />
                        {errors.password && (
                          <div className="text-danger fs-12">
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-row mb-3">
                      <div className="col-md-6">
                        <h5>Location Details</h5>

                        <div className="row">
                          <div className="form-group col-md-6">
                            <label className="">LAT</label>
                            <input
                              type="number"
                              value={lat}
                              onChange={(e) => setLat(e.target.value)}
                              placeholder="LAT"
                              className="form-control"
                            />
                            {errors.lat && (
                              <div className="text-danger fs-12">
                                {errors.lat}
                              </div>
                            )}
                          </div>

                          <div className="form-group col-md-6">
                            <label className="">LONG</label>
                            <input
                              type="number"
                              value={long}
                              onChange={(e) => setLong(e.target.value)}
                              placeholder="LONG"
                              className="form-control"
                            />
                            {errors.long && (
                              <div className="text-danger fs-12">
                                {errors.long}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <h5>Other Details</h5>
                          </div>

                          <div className="form-group col-md-6">
                            <label className="">HP</label>
                            <input
                              type="number"
                              min="0"
                              value={hp}
                              onChange={(e) => setHp(e.target.value)}
                              placeholder="HP"
                              className="form-control"
                            />
                            {errors.hp && (
                              <div className="text-danger fs-12">
                                {errors.hp}
                              </div>
                            )}
                          </div>

                          <div className="form-group col-md-6">
                            <label className="">PUMP DEPTH</label>
                            <input
                              type="number"
                              min="0"
                              value={pumpDepth}
                              onChange={(e) => setPumpDepth(e.target.value)}
                              placeholder="PUMP DEPTH"
                              className="form-control"
                            />
                            {errors.pumpDepth && (
                              <div className="text-danger fs-12">
                                {errors.pumpDepth}
                              </div>
                            )}
                          </div>

                          {/* <div className="form-group col-md-3">
                              <label className="">A</label>
                              <input
                                type="text"
                                value={a}
                                onChange={(e) => setA(e.target.value)}
                                placeholder="A"
                                className="form-control"
                                disabled
                              />
                              {errors.a && (
                                <div className="text-danger fs-12">
                                  {errors.a}
                                </div>
                              )}
                            </div> */}

                          {/* <div className="form-group col-md-3">
                              <label className="">B</label>
                              <input
                                type="number"
                                value={b}
                                onChange={(e) => setB(e.target.value)}
                                placeholder="B"
                                className="form-control"
                              />
                              {errors.b && (
                                <div className="text-danger fs-12">
                                  {errors.b}
                                </div>
                              )}
                            </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="form-row mb-3">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <h5>User Details</h5>
                          </div>

                          <div className="form-group col-md-6">
                            <label className="">Name</label>
                            <input
                              type="text"
                              value={custName}
                              onChange={(e) => setCustName(e.target.value)}
                              placeholder="Name"
                              className="form-control"
                            />
                            {errors.custName && (
                              <div className="text-danger fs-12">
                                {errors.custName}
                              </div>
                            )}
                          </div>

                          <div className="form-group col-md-6">
                            <label className="">Phone Number</label>
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={custNumber}
                              defaultCountry="IN"
                              onChange={setCustNumber}
                            />
                            {errors.custNumber && (
                              <div className="text-danger fs-12">
                                {errors.custNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {getRole() == 2 ?<div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <h5>Permission</h5>
                          </div>

                          <div className="form-group col-md-12">
                            <label className="required-field">Employees</label>
                            <Select
                              class="form-control"
                              value={permission}
                              onChange={(option) => {
                                console.log("options", option);

                                if (
                                  (option ?? []).find((x) => x.value == 0)
                                ) {
                                  setPermission([
                                    {
                                      value: 0,
                                      label: "ALL Employees",
                                    },
                                  ]);
                                } else {
                                  setPermission(option);
                                }
                              }}
                              styles={colourStyles}
                              isMulti={true}
                              options={[
                                {
                                  value: 0,
                                  label: "ALL Employees",
                                },

                                //...(permission??[].find((x)=>x.value == "0" )?[]:employees.map((data) => {
                                ...employees.map((data) => {
                                  return {
                                    value: data.id,
                                    label:
                                      data.id +
                                      " - " +
                                      data.name +
                                      "(" +
                                      data.phone +
                                      ")",
                                  };
                                }),
                              ]}
                            ></Select>

                            {errors.permission && (
                              <div className="text-danger fs-12">
                                {errors.permission}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>:<></>}
                    </div>

                    <div className="form-row mb-3">
                      <div className="col-md-12">
                        <h5>Address Details</h5>
                      </div>

                      <div className="form-group col-md-3">
                        <label className="required-field">State</label>
                        <Select
                          class="form-control"
                          value={state}
                          onChange={(option) => setState(option)}
                          styles={colourStyles}
                          options={[
                            {
                              value: "select",
                              label: "Select State",
                            },

                            ...stateData.map((data) => {
                              return {
                                value: data.state,
                                label: data.state,
                              };
                            }),
                          ]}
                        ></Select>

                        {errors.state && (
                          <div className="text-danger fs-12">
                            {errors.state}
                          </div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label className="">District</label>
                        <Select
                          class="form-select"
                          value={district}
                          onChange={(option) => setDistrict(option)}
                          styles={colourStyles}
                          options={[
                            {
                              value: "select",
                              label: "Select District",
                            },

                            ...(state.value != "select"
                              ? stateData
                                  .find((x) => x.state == state.value)
                                  .districts.map((data) => {
                                    return {
                                      value: data,
                                      label: data,
                                    };
                                  })
                              : []),
                          ]}
                        ></Select>

                        {errors.district && (
                          <div className="text-danger fs-12">
                            {errors.district}
                          </div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label className="">Village</label>
                        <input
                          type="text"
                          value={village}
                          onChange={(e) => setVillage(e.target.value)}
                          placeholder="Village"
                          className="form-control"
                        />
                        {errors.village && (
                          <div className="text-danger fs-12">
                            {errors.village}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setShowDeviceModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"> </i>
                  Close
                </button>

                <button type="submit" className="btn btn-primary">
                  {isEdit ? "Save" : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div class="mr-auto">
                <h4 className="card-title">All Devices</h4>
              </div>

              <div class="col-md-6 mb-2">
                <div className="input-group search-area mr-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (searchTimeout) {
                        clearTimeout(searchTimeout);
                      }
                      loadDevices();
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </form>
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>

              {getRole() == 2 ||
              (getRole() == 3 && (profile.device_add ?? false)) ? (
                <Button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => {
                    resetForm();
                    setShowDeviceModal(true);
                  }}
                >
                  Add Device
                </Button>
              ) : (
                <></>
              )}

              {xlsData.length > 0 ? (
                <CSVLink
                  ref={csvInstance}
                  data={xlsData}
                  filename={"devices.csv"}
                  className="btn btn-sm btn-primary mr-2"
                  target="_blank"
                >
                  Export
                </CSVLink>
              ) : (
                <Button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => initXlsData()}
                >
                  Export
                </Button>
              )}
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : devices.length == 0 ? (
                <p>No Device Found</p>
              ) : (
                <DTable
                  columns={columns}
                  data={devices}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Messages;
