import React, { Fragment, useState, useEffect } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import { profileAction } from "../../../store/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/AxiosInstance";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const profile = useSelector((state) => state.auth.profile);

  useEffect(() => {
    loadProfile();
    //dispatch(getProfile());
  }, [dispatch]);

  async function loadProfile() {
    try {
      const { data } = await axiosInstance.get("company/profile");

      console.log("profile data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        //setUser(data.user);
        dispatch(profileAction(data.profile));
        console.log("profile data - " + JSON.stringify(data));
      }
    } catch (error) {}
  }

  return (
    <Fragment>
      <NavHader />
      <SideBar
        onClick={() => onClick2()}
        onClick3={() => onClick3()}
        profile={profile}
      />
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        profile={profile}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      {/* <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} /> */}
    </Fragment>
  );
};

export default JobieNav;
