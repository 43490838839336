const profileTypeList = [
  {
    id:0,
    title:"Text",
    active:true
  },
  {
    id:2,
    title:"Quick Reply Button",
    active:true
  },
  {
    id:4,
    title:"Call To Action Button",
    active:false
  },
  {
    id:6,
    title:"List/Menu",
    active:false
  }
  ];

  export default profileTypeList