import Table from "react-bootstrap/Table";
import { axios } from "axios";
// import { useState ,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import React, { useRef, useState, useEffect, Fragment } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import {
  Button,
  Col,
  Dropdown,
  Modal,
  Nav,
  Row,
  Spinner,
  Form,
} from "react-bootstrap";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import Sonnet from '../../components/Sonnet';

import { format } from "date-fns";
import { Link } from "react-router-dom";

import DTable from "../Table";
import { exportToExcel } from "react-json-to-excel";
import moment from "moment";

import ReactApexChart from "react-apexcharts";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

import GoogleMap from "google-maps-react-markers";
import Marker from "./marker";

function DeviceDetails({ match }) {
  const dispatch = useDispatch();
  const [activeToggle, setActiveToggle] = useState("table");
  const [data, setData] = useState([]);
  const [device, setDevice] = useState(null);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(100);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [startDate, setStartDate] = useState(dateToEpoch2(new Date()));
  const [endDate, setEndDate] = useState(dateToEpoch2(new Date()));

  const [filter, setFilter] = useState(false);

  const [statistics, setStatistics] = useState(null);
  const [timeFilter, setTimeFilter] = useState("today");
  const [loadFilter, setLoadFilter] = useState("all");
  const chartRef = useRef();
  const chartRefLegend = useRef();

  const [timeFilter2, setTimeFilter2] = useState("today");
  const [loadFilter2, setLoadFilter2] = useState("all");

  const chartRef2 = useRef();
  const chartRef2Legend = useRef();
  const chartRef3 = useRef();

  const colors = [
    "#008ffb",
    "#00e396",
    "#feb019",
    "#ff4560",
    "#775dd0",
    "#353f45",
    "#7b5446",
    "#ff5606",
  ];




  const [chart1Selected, setChart1Selected] = useState([1, 2]);

  const [chart1Loading, setChart1Loading] = useState(false);
  const [chart1, setChart1] = useState([
    {
      name: "LOAD",
      field: "LOAD",
      show: true,
      min: 1,
      max: 4,
      showAlways: true,
      tickAmount: 1,
    },

    {
      name: "FLOW RATE",
      field: "POPFLW1",
      show: true,
      suffix: "lpm",
    },

    {
      name: "DISCHARGE",
      field: "POPDWD1",
      show: true,
      suffix: "Ltr",
    },

    {
      name: "CUMULATIVE DISCHARGE",
      field: "POPTOTWD1",
      show: false,
      suffix: "Ltr",
    },

    {
      name: "RUNNIG Hrs",
      field: "PDHR1",
      show: false,
      suffix: "Hrs",
    },

    {
      name: "CUMULATIVE RUNNIG Hrs",
      field: "PTOTHR1",
      show: false,
      suffix: "Hrs",
    },

    {
      name: "ENERGY GENERATED",
      field: "PDKWH1",
      show: false,
      suffix: "kWh",
    },

    {
      name: "CUMULATIVE ENERGY GENERATED",
      field: "PTOTKWH1",
      show: false,
      suffix: "kWh",
    },
  ]);

  const [chart2Selected, setChart2Selected] = useState([1, 2]);

  const [chart2Loading, setChart2Loading] = useState(false);
  const [chart2, setChart2] = useState([
    {
      name: "LOAD",
      field: "LOAD",
      show: true,
      min: 1,
      max: 4,
      showAlways: true,
      tickAmount: 1,
    },

    {
      name: "FREQUENCY",
      field: "POPFREQ1",
      show: true,
      suffix: "",
    },

    {
      name: "RMS Voltage",
      field: "POPV1",
      show: true,
      suffix: "V",
    },

    {
      name: "RMS Current",
      field: "POPI1",
      show: false,
      suffix: "Amp",
    },

    {
      name: "Array Power",
      field: "MPPT",
      show: false,
      suffix: "W",
    },

    {
      name: "Array Voltage",
      field: "PDC1V1",
      show: false,
      suffix: "V",
    },

    {
      name: "Array Current",
      field: "PDC1I1",
      show: false,
      suffix: "Amp",
    },

    {
      name: "TEMPRATURE",
      field: "TEMP",
      show: false,
      suffix: "",
    },
  ]);

  const [chart3, setChart3] = useState([
    {
      name: "CUMULATIVE ENERGY GENERATED",
      field: "PTOTKWH1",
      show: true,
      suffix: "kWh",
    },

    {
      name: "CUMULATIVE RUNNIG Hrs",
      field: "PTOTHR1",
      show: false,
      suffix: "Hrs",
    },

    {
      name: "CUMULATIVE DISCHARGE",
      field: "POPTOTWD1",
      show: false,
      suffix: "Ltr",
    },
  ]);


  useEffect(() => {
    if (!chart1Loading) {
      if (chartRefLegend.current) {
        setTimeout(() => {
          try {
            chartRefLegend.current.scrollIntoView({ behavior: "smooth" });
          } catch (e) {}
        }, 200);
      }
    }
  }, [chart1Loading]);

  useEffect(() => {
    if (!chart2Loading) {
      if (chartRef2Legend.current) {
        setTimeout(() => {
          try {
            chartRef2Legend.current.scrollIntoView({ behavior: "smooth" });
          } catch (e) {}
        }, 200);
      }
    }
  }, [chart2Loading]);

  const timeFilterList = [
    {
      label: "Today",
      value: "today",
    },
    {
      value: "week",
      label: "This Week",
    },
    {
      value: "month",
      label: "This Month",
    },
    {
      value: "year",
      label: "This Year",
    },
  ];

  const loadFilterList = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "pump",
      label: "Pump",
    },
    {
      value: "uspc_1",
      label: "USPC 1",
    },
    {
      value: "uspc_2",
      label: "USPC 3",
    },
    {
      value: "uspc_3",
      label: "USPC 4",
    },
  ];

  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
  };

  const onMarkerClick = (markerId) => {
    console.log("This is ->", markerId);
  };

  useEffect(() => {
    if (match.params.id) {
      loadDeviceDetails();
      loadDeviceStatistics();
      loadTabData(true);
    }
  }, [dispatch]);

  useEffect(() => {
    loadDeviceStatistics();
  }, [timeFilter, loadFilter, timeFilter2, loadFilter2]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        console.log("search", "loadTabData");
        loadTabData(true);
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    console.log("tab change", "loadTabData");
    loadTabData(true);
  }, [activeToggle]);

  const loadTabData = (reset) => {
    console.log("loadTabData:", reset);

    if (reset == true) {
      setPage(0);
      setPageSize(100);
      setMaxPage(1);
    }

    //if (activeToggle == "data") {
    loadDeviceData();
    //}

    if (activeToggle == "database" || activeToggle == "table") {
      loadDeviceData();
    } else if (activeToggle == "chart1" || activeToggle == "chart2") {
      loadDeviceData();
    }
  };

  useEffect(() => {
    loadTabData(true);
  }, [filter]);

  useEffect(() => {
    if (filter) {
      loadTabData(true);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [data]);

  const loadDeviceDetails = async () => {
    const { data } = await axiosInstance.get(
      "company/device/" + match.params.id
    );

    if (data.status) {
      setDevice(data.device);
      document.getElementById("nav_title").innerHTML = data.device.S_NO;
    } else {
      setDevice(null);
    }
  };

  //Devices
  const loadDeviceData = async () => {
    setLoading(true);
    setChart1Loading(true);
    setChart2Loading(true);

    try {
      const { data } = await axiosInstance.post("company/device-data", {
        page: page,
        limit: pageSize,
        search: search,
        id: match.params.id,
        filter: filter,
        start_date: startDate,
        end_date: endDate,
      });

      if (data.status) {
        var maxPage2 = Math.ceil(data.total / pageSize);
        console.log("max page - " + maxPage2);
        setMaxPage(maxPage2);
        setData(data.data);
      } else {
        setMaxPage(1);
        setData([]);
      }
      setChart1Loading(false);
      setChart2Loading(false);
    } catch (e) {
      setChart1Loading(false);
      setChart2Loading(false);
    }
  };

  const loadDeviceStatistics = async () => {
    const { data } = await axiosInstance.post("company/device-statistics", {
      device_id: match.params.id,
      time_filter: timeFilter,
      load_filter: loadFilter,

      time_filter2: timeFilter2,
      load_filter2: loadFilter2,
    });

    if (data.status) {
      console.log("statistics", JSON.stringify(data.statistics));
      setStatistics(data.statistics);
    } else {
      setStatistics(null);
    }
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  const dateToUTC = (date) => {
    //return date;
    return new Date(date);
    //return moment(date, "YYYY-MM-DD HH:mm:ss").toDate();
  };

  useEffect(() => {
    loadTabData(false);
  }, [page, pageSize]);

  const status = (data) => {
    if (data.COND == 300 && data.PRUNST1 == 1) {
      return "RUN";
    } else if (data.COND == 300 && data.PRUNST1 == 0) {
      return "STOP";
    } else if (data.COND == 306) {
      return "LOW POWER";
    } else if (data.COND == 302) {
      return "FAULT-SHORT CIRCUIT";
    } else if (data.COND == 307) {
      return "FAULT-DRY RUN";
    } else if (data.COND == 301) {
      return "FAULT-OVER CURRENT";
    } else if (data.COND == 303) {
      return "FAULT-OVER VOLTAGE";
    } else if (data.COND == 308) {
      return "FAULT-OPEN CIRCUIT";
    } else if (data.COND == 309) {
      return "FAULT-OVER TEMPRATURE";
    } else if (data.COND == 310) {
      return "FAULT-LOW WATER LEVEL";
    } else if (data.COND == 318) {
      return "FAULT-BOOST SATURATION";
    }
  };

  const columns2 = [
    {
      Header: "No",
      accessor: "",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "TIMESTAMP",
      accessor: "TIMESTAMP",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return d.TIMESTAMP
          ? moment(new Date(d.TIMESTAMP)).utc().format("DD-MM-YYYY hh:mm A") //format(new Date(d.TIMESTAMP), "dd/MM/yyyy H:mma",{ timeZone: 'Asia/Calcutta' })
          : "No";
      },
    },
    {
      Header: "LOAD",
      accessor: "LOAD",
      sort: false,
    },
    {
      Header: "STATUS",
      accessor: "STATUS",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return status(d);
      },
    },
    {
      Header: "FREQUENCY",
      accessor: "POPFREQ1",
      sort: false,
    },
    {
      Header: "RMS Voltage (V)",
      accessor: "POPV1",
      sort: false,
    },

    {
      Header: "RMS Current (Amp)",
      accessor: "POPI1",
      sort: false,
    },
    {
      Header: "Array Power (W)",
      accessor: "MPPT",
      sort: false,
    },
    {
      Header: "Array Voltage (V)",
      accessor: "PDC1V1",
      sort: false,
    },
    {
      Header: "Array Current (Amp)",
      accessor: "PDC1I1",
      sort: false,
    },
    {
      Header: "TEMPRATURE",
      accessor: "TEMP",
      sort: false,
    },
    {
      Header: "VDC",
      accessor: "V_DC",
      sort: false,
    },
    {
      Header: "DUTY",
      accessor: "DUTY",
      sort: false,
    },
    {
      Header: "Flow Rate (LPM)",
      accessor: "POPFLW1",
      sort: false,
    },
    {
      Header: "DISCHARGE (In Ltr)",
      accessor: "POPDWD1",
      sort: false,
    },
    {
      Header: "CUMULATIVE DISCHARGE (In Ltr)",
      accessor: "POPTOTWD1",
      sort: false,
    },
    {
      Header: "RUNNIG Hrs",
      accessor: "PDHR1",
      sort: false,
    },
    {
      Header: "CUMULATIVE RUNNIG Hrs",
      accessor: "PTOTHR1",
      sort: false,
    },
    {
      Header: "ENERGY GENERATED (kWh)",
      accessor: "PDKWH1",
      sort: false,
    },
    {
      Header: "CUMULATIVE ENERGY GENERATED (kWh)",
      accessor: "PTOTKWH1",
      sort: false,
    },
  ];

  const onExportReport = async () => {
    const { data } = await axiosInstance.post("company/device-data", {
      page: page,
      limit: 9999999999,
      search: search,
      id: match.params.id,
      filter: filter,
      start_date: startDate,
      end_date: endDate,
    });

    if (data.status) {
      const report = data.data.map((x, i) => {
        return {
          No: i + 1,
          //"TIMESTAMP":x.TIMESTAMP,
          TIMESTAMP: moment(new Date(x.TIMESTAMP))
            .utc()
            .format("DD-MM-YYYY hh:mm A"),
          LOAD: x.LOAD,
          STATUS: status(x),
          FREQUENCY: x.POPFREQ1,
          "RMS Voltage (V)": x.POPV1,
          "RMS Current (Amp)": x.POPI1,
          "Array Power (W)": x.MPPT,
          "Array Voltage (V)": x.PDC1V1,
          "Array Current (Amp)": x.PDC1I1,
          TEMPRATURE: x.TEMP,
          VDC: x.V_DC,
          DUTY: x.DUTY,
          "Flow Rate (LPM)": x.POPFLW1,
          "DISCHARGE (In Ltr)": x.POPDWD1,
          "CUMULATIVE DISCHARGE (In Ltr)": x.POPTOTWD1,
          "RUNNIG Hrs": x.PDHR1,
          "CUMULATIVE RUNNIG Hrs": x.PTOTHR1,
          "ENERGY GENERATED (kWh)": x.PDKWH1,
          "CUMULATIVE ENERGY GENERATED (kWh)": x.PTOTKWH1,
        };
      });

      exportToExcel(
        report,
        `${device.S_NO}-REPORT-${moment().format("DD-MM-YYYY h:mm")}.csv`
      );
    }
  };

  const onDateFilter = async () => {
    setFilter(true);
  };

  const mapIcon = () => {
    const status = statistics?.status ?? "";

    var icon =
    "https://img.icons8.com/fluency-systems-filled/30/FF0000/marker.png";

  if (status == "RUN") {
    icon =
      "https://img.icons8.com/fluency-systems-filled/30/208420/marker.png";
  } else if (status == "STOP") {
    icon =
      "https://img.icons8.com/fluency-systems-filled/30/FF0000/marker.png";
  } else if (status == "DISCONNECTED") {
    icon =
      "https://img.icons8.com/fluency-systems-filled/30/797979/marker.png";
  } else if (status.startsWith("FAULT")) {
    icon =
      "https://img.icons8.com/fluency/30/null/general-warning-sign.png";
  } else {
    icon =
      "https://img.icons8.com/fluency-systems-filled/30/82C4E4/marker.png";
  }

    return icon;
  };

  function dateToEpoch2(thedate) {
    //const date = thedate.setHours(0,0,0,0);
    //return moment(date).toDate();
    return thedate.toJSON().split("T")[0];
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">ASN_11</label><br/>
                      <h4 className="mb-0">{device?.ASN_11}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">IMEI</label><br/>
                      <h4 className="mb-0">{device?.IMEI}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">SIMCARD_NO</label><br/>
                      <h4 className="mb-0">{device?.SIMCARD_NO ?? "No"}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">STATUS</label><br/>
                      <h4 className="mb-0">{statistics?.status}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">ARRAY POWER</label><br/>
                      <h4 className="mb-0">{statistics?.last_data?.MPPT} W</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">FREQUENCY</label><br/>
                      <h4 className="mb-0">
                        {statistics?.last_data?.POPFREQ1} Hz
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">MQTT STATUS</label><br/>
                      <h4 className="mb-0">
                        {statistics?.last_data?.V_1 == 1
                          ? "CONNECTED"
                          : "DISCONNECTED"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">SIGNAL STENGHT</label><br/>
                      <h4 className="mb-0">{statistics?.last_data?.RSSI}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">LAST UPDATE</label><br/>
                      <h4 className="mb-0">
                        {statistics?.last_data != null
                          ? moment(new Date(statistics?.last_data?.TIMESTAMP))
                              .utc()
                              .format("DD-MM-YYYY hh:mm A")
                          : "Not Available"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">VILLAGE</label><br/>
                      <h4 className="mb-0">{device?.VILLAGE}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">USER</label><br/>
                      <h4 className="mb-0">{device?.CUSTOMER_NAME}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">MOBILE NUMBER</label><br/>
                      <h4 className="mb-0">{device?.CUSTOMER_PHONE}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">COMPANY</p>
                      <h4 className="mb-0">
                        {companyName(device?.COMPANY_ID)}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">STATE</label><br/>
                      <h4 className="mb-0">{device?.STATE}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-2">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <label className="">DISTRICT</label><br/>
                      <h4 className="mb-0">{device?.DISTRICT}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 

        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <div className="media-body">
                  <Row>
                    <Col>
                      <label className="">RUN HRS</p>
                    </Col>

                    <Col>
                      <Dropdown className="dropdown m-0 p-0">
                        <Dropdown.Toggle
                          variant=""
                          //as="button"
                          className="btn btn-sm rounded border border-light dropdown-toggle"
                        >
                          {
                            loadFilterList.find((x) => x.value == loadFilter2)
                              ?.label
                          }
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right">
                          {loadFilterList.map((x) => (
                            <Dropdown.Item
                              key={x.value}
                              onClick={(e) => setLoadFilter2(x.value)}
                            >
                              {x.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>

                    <Col>
                      <Dropdown className="dropdown m-0 p-0">
                        <Dropdown.Toggle
                          variant=""
                          //as="button"
                          className="btn btn-sm rounded border border-light dropdown-toggle"
                        >
                          {
                            timeFilterList.find((x) => x.value == timeFilter2)
                              ?.label
                          }
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right">
                          {timeFilterList.map((x) => (
                            <Dropdown.Item
                              key={x.value}
                              onClick={(e) => setTimeFilter2(x.value)}
                            >
                              {x.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>

                  <div id="chart">
                    <ReactApexChart
                      options={options2}
                      series={series2}
                      type="area"
                      height={200}
                    />
                  </div>

                  <ul className='list-group list-group-flush'>
                <li className='list-group-item d-flex px-0 justify-content-between'>
                  <b>RUN HR</b>
                  <span className='mb-0'>0.0</span>
                </li>
                <li className='list-group-item d-flex px-0 justify-content-between'>
                  <b>TOTAL RUN HR</b>
                  <span className='mb-0'>0.0</span>
                </li>
               
              </ul>

                </div>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
       
        <div className="col-md-6">
          <div className="col-12">
            <div className="widget-stat card">
              <div className="card-body p-0">
                <div className="media ai-icon">
                  <div className="media-body">
                    <Row className="p-4">
                      <Col>
                        <label className="mb-1">ENERGY</label>
                      </Col>

                      <Col>
                        <Dropdown className="dropdown m-0 p-0">
                          <Dropdown.Toggle
                            variant=""
                            //as="button"
                            className="btn btn-sm rounded border border-light dropdown-toggle"
                          >
                            {
                              loadFilterList.find((x) => x.value == loadFilter)
                                ?.label
                            }
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            {loadFilterList.map((x) => (
                              <Dropdown.Item
                                key={x.value}
                                onClick={(e) => setLoadFilter(x.value)}
                              >
                                {x.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>

                      <Col>
                        <Dropdown className="dropdown m-0 p-0">
                          <Dropdown.Toggle
                            variant=""
                            //as="button"
                            className="btn btn-sm rounded border border-light dropdown-toggle"
                          >
                            {
                              timeFilterList.find((x) => x.value == timeFilter)
                                ?.label
                            }
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            {timeFilterList.map((x) => (
                              <Dropdown.Item
                                key={x.value}
                                onClick={(e) => setTimeFilter(x.value)}
                              >
                                {x.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>

                    <div id="">
                      {statistics == null ? (
                        <div className="text-center py-4">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                        <ReactApexChart
                          ref={chartRef3}
                          options={{
                            chart: {
                              height: 380,
                              type: "line",
                              zoom: {
                                enabled: false,
                              },
                              events: {
                                legendClick: function (
                                  chartContext,
                                  seriesIndex,
                                  config
                                ) {
                                  console.log("legend click", seriesIndex);
                                },
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            colors: colors,
                           
                            tooltip: {
                              onDatasetHover: {
                                highlightDataSeries: true,
                              },
                              // x: {
                              //   show: true,
                              //   format: "dd-MM-yyyy HH:mm",
                              //   formatter: undefined,
                              // },
                            },

                            legend: {
                              show: false,
                              //showForSingleSeries: true,
                              //floating: true,
                            },
                            // title: {
                            //   text: "TIMESTAMP",
                            //   align: "left",
                            // },
                            markers: {
                              size: 0,
                            },
                            // xaxis: {
                            //   type: "datetime",
                            // },

                            xaxis: {
                              type: "text",
                              categories: statistics.chartData.map(
                                (e) => e.label
                              ),
                              labels: {
                                show: true,
                                rotate: -90,
                                style: {
                                  colors: [],
                                  fontSize: '10px',
                                  fontFamily: 'poppins, sans-serif',
                                  fontWeight: 400,
                                  cssClass: 'apexcharts-xaxis-label',
                              },
                                }
                            },

                            stroke: {
                              curve: "straight",
                              show: chart3.map((x, i) => true),
                              width: chart3.map((x, i) => 2),
                            },
                            yaxis: chart3.map((x, i) => {
                              return {
                                min: x.min,
                                // min:  x.min ? x.min : (min) => {
                                //   console.log("MIN: ",x.field)
                                //   console.log("MIN: ",min)
                                //   return min;
                                // },
                                //minimumValue(statistics.chartData,x.field),
                                //min:(_)=>minimumValue(statistics.chartData,x.field),
                                //logarithmic:false,
                                max: x.max,
                                forceNiceScale: true,
                                //forceNiceScale:false,
                                //tickAmount: 0.5,
                                //logarithmic: true,
                                showAlways: x.showAlways,
                                //show: x.show ?? true,
                                seriesName: x.name,
                                tickAmount: x.tickAmount,
                                opposite: i != 0,
                                title: {
                                  //text: i == 0 ? x.name :undefined,
                                  text: x.name,
                                  rotate: -90,
                                  offsetX: 0,
                                  offsetY: 0,
                                  style: {
                                    colors: colors[i],
                                    fontSize: "10px",
                                    fontFamily: 'poppins, sans-serif',
                                    fontWeight: 300,
                                    cssClass: "apexcharts-yaxis-title",
                                  },
                                },
                                labels: {
                                  style: {
                                    colors: colors[i],
                                    fontSize: "10px",
                                    fontFamily: 'poppins, sans-serif',
                                    fontWeight: 300,
                                    cssClass: "apexcharts-yaxis-label",
                                  },
                                  formatter: (value) =>
                                    (value ?? 0.0).toFixed(2) +
                                    (x.suffix ? `${x.suffix}` : ""),
                                },
                              };
                            }),
                          }}
                          series={chart3.map((x) => {
                            return {
                              name: x.name,
                              data: statistics.chartData.map((d) => {
                                return { x: d.label, y: d[x.field] };
                              }),
                            };
                          })}
                          type="line"
                          height={380}
                        />
                      )}
                    </div>

                    {/* <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex px-0 justify-content-between">
                        <b>GENERATED ENERGY</b>
                        <span className="mb-0">0.0</span>
                      </li>
                      <li className="list-group-item d-flex px-0 justify-content-between">
                        <b>CUMMULATIOVE GENERATED ENERGY</b>
                        <span className="mb-0">0.0</span>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-12">
          <div className="widget-stat card">
            <div className="card-body p-0">
              <div className="media ai-icon">
                <div className="media-body">
                  <label className="p-2">MAP</label>

                  <>
                    <GoogleMap
                      apiKey="AIzaSyCgaUL7e5aiZv_wxwG2mgUIvv1QkQbtPGM"
                      defaultCenter={{
                        lat: 28.6448,
                        lng: 77.216721,
                      }}
                      defaultZoom={5}
                      options={{
                        mapTypeControl: false,
                        fullscreenControl: false,
                        maxZoom: 20,
                        minZoom: 3,
                        rotateControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        mapTypeId: "roadmap",
                        zoomControl: true,
                      }}
                      mapMinHeight="220px"
                      onGoogleApiLoaded={onGoogleApiLoaded}
                      onChange={(map) => console.log("Map moved", map)}
                    >
                      <Marker
                        lat={device ? device.LAT : 0}
                        lng={device ? device.LONG : 0}
                        markerId={"Device Name"}
                        src={mapIcon()}
                        onClick={onMarkerClick}
                      />
                    </GoogleMap>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Card>
        <Card.Header>
          <Tab.Container defaultActiveKey="table">
            <Nav
              as="ul"
              className="nav-pills mb-4 light"
              role="tablist"
              defaultActiveKey={"table"}
              activeKey={activeToggle}
              onSelect={(k) => setActiveToggle(k)}
            >
              <Nav.Item>
                <Nav.Link eventKey="table">Table</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="chart1">Chart 1</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="chart2">Chart 2</Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </Card.Header>
        <Card.Body>
          <Row className="mb-4">
            <Col md="4">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  inputVariant="outlined"
                  label="Start Date"
                  value={startDate}
                  format="dd/MM/yyyy"
                  placeholder="dd/MM/yyyy"
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Col>
            <Col md="4">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  inputVariant="outlined"
                  label="End Date"
                  value={endDate}
                  format="dd/MM/yyyy"
                  placeholder="dd/MM/yyyy"
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Col>

            <Col md="4">
              {filter ? (
                <Button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setFilter(false);
                    setStartDate(dateToEpoch2(new Date()));
                    setEndDate(dateToEpoch2(new Date()));
                  }}
                >
                  Clear Filter
                </Button>
              ) : (
                <Button
                  className="btn btn-sm btn-primary"
                  onClick={() => onDateFilter()}
                >
                  Apply Filter
                </Button>
              )}
            </Col>
          </Row>

          {activeToggle == "table" ? (
            <>
              <Row>
                <Col>
                  <Card.Title>{device?.S_NO ?? ""}'s Table</Card.Title>
                </Col>

                <Col className="text-right">
                  <Button
                    className="btn btn-sm btn-primary ml-2"
                    onClick={() => onExportReport()}
                  >
                    Export Table
                  </Button>
                </Col>
              </Row>

              {data.length == 0 ? (
                <p>No Device Report Found</p>
              ) : loading ? (
                <div className="text-center py-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <DTable
                  columns={columns2}
                  data={data}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}
            </>
          ) : (
            <></>
          )}

          {activeToggle == "chart1" ? (
            <>
              {loading || chart1Loading ? (
                <div className="text-center py-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  <ReactApexChart
                    ref={chartRef}
                    options={{
                      chart: {
                        height: 500,
                        type: "line",
                        zoom: {
                          enabled: false,
                        },
                        events: {
                          legendClick: function (
                            chartContext,
                            seriesIndex,
                            config
                          ) {
                            console.log("legend click", seriesIndex);
                            //console.log("legend config", config);

                            if (seriesIndex != 0) {
                              var dummy = chart1;

                              if (chart1[seriesIndex].show) {
                                chartRef?.current?.chart.hideSeries(
                                  chart1[seriesIndex].name
                                );
                                dummy[seriesIndex].show = false;
                              } else {
                                chartRef?.current?.chart.showSeries(
                                  chart1[seriesIndex].name
                                );
                                dummy[seriesIndex].show = true;
                              }

                              console.log("new chart1", JSON.stringify(dummy));
                              setChart1(dummy);

                              //chartContext.toggleSeries(chart1[seriesIndex].name)
                            }
                          },
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      colors: colors,
                      stroke: {
                        curve: "straight",
                        show: chart1.map((x, i) => i == 0 || x.show),
                        width: chart1.map((x, i) => (i == 0 || x.show ? 2 : 0)),
                      },

                      tooltip: {
                        enabledOnSeries: [
                          ...chart1.map((x, i) => (x.show ? i : 0)),
                        ],
                        onDatasetHover: {
                          highlightDataSeries: true,
                        },
                        x: {
                          show: true,
                          format: "dd-MM-yyyy HH:mm",
                          formatter: undefined,
                        },
                      },

                      legend: {
                        show: false,
                        //showForSingleSeries: true,
                        //floating: true,
                      },
                      // title: {
                      //   text: "TIMESTAMP",
                      //   align: "left",
                      // },
                      markers: {
                        size: 0,
                      },
                      xaxis: {
                        type: "datetime",
                      },
                    
                      yaxis: chart1.map((x, i) => {
                        return {
                          min: x.min,
                          //min:  x.min ? x.min : () =>  minimumValue(data,x.field),
                          //min:(_)=>minimumValue(data,x.field),
                          max: x.max,
                          forceNiceScale: true,
                          //tickAmount: 0.5,
                          //logarithmic: true,
                          showForNullSeries: true,
                          show: x.show ?? true,
                          seriesName: x.name,
                          tickAmount: x.tickAmount,
                          opposite: i != 0,
                          title: {
                            //text: i == 0 ? x.name :undefined,
                            text: x.name,
                            rotate: -90,
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                              colors: colors[i],
                              fontSize: "10px",
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontWeight: 300,
                              cssClass: "apexcharts-yaxis-title",
                            },
                          },
                          labels: {
                            //show:false,
                            //show :i == 0,
                            //rotate:90,
                            style: {
                              colors: colors[i],
                              fontSize: "10px",
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontWeight: 300,
                              cssClass: "apexcharts-yaxis-label",
                            },
                            formatter: (value) => i == 0? value :
                              (value ?? 0.0).toFixed(2) +
                              (x.suffix ? `${x.suffix}` : ""),
                          },
                        };
                      }),
                    }}
                    series={chart1.map((x) => {
                      return {
                        name: x.name,
                        data: data.map((d) => {
                          return { x: dateToUTC(d.TIMESTAMP), y: d[x.field] };
                        }),
                      };
                    })}
                    type="line"
                    height={500}
                  />

                  <Row ref={chartRefLegend}>
                    {chart1.map((x, i) => {
                      return (
                        <Col key={"legend-" + i}>
                          <div>
                            <Form.Check
                              label={x.name}
                              name={x.field}
                              disabled={i == 0}
                              style={{
                                color: colors[i],
                              }}
                              value={x.show}
                              checked={x.show}
                              onClick={(e) => {


                                var dummy = chart1;

                                setChart1Loading(true);

                                dummy[i].show = e.target.checked;

                                //console.log("chart1 newData ",JSON.stringify(dummy))
                                setChart1(dummy);

                                setTimeout(() => {
                                  setChart1Loading(false);
                                }, 200);

                                return;

                                console.log(
                                  "chart1 check " + x.name,
                                  e.target.checked
                                );
                                var dummy = chart1;

                                const selected = chart1.filter((x) => x.show);

                                console.log("chart1 selcted", selected.length);

                                if (selected.length == 3 && e.target.checked) {
                                  console.log("chart1 selcted is 3");

                                  var newSelected = [];
                                  if (chart1Selected.length == 0) {
                                    newSelected = [i];
                                  } else if (chart1Selected.length == 1) {
                                    newSelected = [chart1Selected[0], i];
                                  } else {
                                    dummy[chart1Selected[0]].show = false;
                                    newSelected = [chart1Selected[1], i];
                                  }

                                  setChart1Selected(newSelected);
                                  console.log(
                                    "chart1 new selected",
                                    JSON.stringify(newSelected)
                                  );

                                  chart1.map((x, i) => {
                                    dummy[i].show =
                                      i == 0 || newSelected.includes(i);
                                  });
                                } else {
                                  var newSelected = [];
                                  selected.map((s, i) => {
                                    if (i != 0) {
                                      newSelected.push(
                                        chart1.findIndex(
                                          (x) => x.field == s.field
                                        )
                                      );
                                    }
                                  });
                                  setChart1Selected(newSelected);
                                }

                                setChart1Loading(true);

                                dummy[i].show = e.target.checked;

                                //console.log("chart1 newData ",JSON.stringify(dummy))
                                setChart1(dummy);

                                setTimeout(() => {
                                  setChart1Loading(false);
                                }, 200);
                              }}
                              type={"checkbox"}
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </>
          ) : (
            <></>
          )}

          {activeToggle == "chart2" ? (
            <>
              {loading || chart2Loading ? (
                <div className="text-center py-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  <ReactApexChart
                    ref={chartRef2}
                    options={{
                      chart: {
                        height: 500,
                        type: "line",
                        zoom: {
                          enabled: false,
                        },
                        events: {
                          legendClick: function (
                            chartContext,
                            seriesIndex,
                            config
                          ) {
                            console.log("legend click", seriesIndex);
                            //console.log("legend config", config);

                            // if (seriesIndex != 0) {
                            //   var dummy = chart1;

                            //   if (chart1[seriesIndex].show) {
                            //     chartRef?.current?.chart.hideSeries(
                            //       chart1[seriesIndex].name
                            //     );
                            //     dummy[seriesIndex].show = false;
                            //   } else {
                            //     chartRef?.current?.chart.showSeries(
                            //       chart1[seriesIndex].name
                            //     );
                            //     dummy[seriesIndex].show = true;
                            //   }

                            //   console.log("new chart1", JSON.stringify(dummy));
                            //   setChart1(dummy);
                            // }
                          },
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      colors: colors,
                      stroke: {
                        curve: "straight",
                        show: chart2.map((x, i) => i == 0 || x.show),
                        width: chart2.map((x, i) => (i == 0 || x.show ? 2 : 0)),
                      },

                      tooltip: {
                        enabledOnSeries: [
                          ...chart2.map((x, i) => (x.show ? i : 0)),
                        ],
                        onDatasetHover: {
                          highlightDataSeries: true,
                        },
                        x: {
                          show: true,
                          format: "dd-MM-yyyy HH:mm",
                          formatter: undefined,
                        },
                      },

                      legend: {
                        show: false,
                        //showForSingleSeries: true,
                        //floating: true,
                      },
                      // title: {
                      //   text: "TIMESTAMP",
                      //   align: "left",
                      // },
                      markers: {
                        size: 0,
                      },
                      xaxis: {
                        type: "datetime",
                        // labels: {
                        //   formatter: function(value) {
                        //      return "";
                        // }}
                      },
                    
                      yaxis: chart2.map((x, i) => {
                        return {
                          max: x.max,
                          forceNiceScale: true,
                          //tickAmount: 0.5,
                          //logarithmic: true,
                          //showAlways: x.showAlways,
                          showAlways: i == 0,
                          showForNullSeries: false,
                          show: x.show ?? true,
                          seriesName: x.name,
                          tickAmount: x.tickAmount,
                          opposite: i != 0,
                          title: {
                            //text: i == 0 ? x.name :undefined,
                            text: x.name,
                            rotate: -90,
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                              colors: colors[i],
                              fontSize: "10px",
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontWeight: 300,
                              cssClass: "apexcharts-yaxis-title",
                            },
                          },
                          labels: {
                            //show:false,
                            //show :i == 0,
                            //rotate:90,
                            style: {
                              colors: colors[i],
                              fontSize: "10px",
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontWeight: 300,
                              cssClass: "apexcharts-yaxis-label",
                            },
                            formatter: (value) =>i == 0? value :
                              (value ?? 0.0).toFixed(2) +
                              (x.suffix ? `${x.suffix}` : ""),
                          },
                        };
                      }),
                    }}
                    series={chart2.map((x) => {
                      return {
                        name: x.name,
                        data: data.map((d) => {
                          return { x: dateToUTC(d.TIMESTAMP), y: d[x.field] };
                        }),
                      };
                    })}
                    type="line"
                    height={500}
                  />

                  <Row ref={chartRef2Legend}>
                    {chart2.map((x, i) => {
                      return (
                        <Col key={"legend-" + i}>
                          <div>
                            <Form.Check
                              label={x.name}
                              name={x.field}
                              disabled={i == 0}
                              style={{
                                color: colors[i],
                              }}
                              value={x.show}
                              checked={x.show}
                              onClick={(e) => {

                                var dummy = chart2;

                                setChart2Loading(true);

                                dummy[i].show = e.target.checked;

                                //console.log("chart1 newData ",JSON.stringify(dummy))
                                setChart2(dummy);

                                setTimeout(() => {
                                  setChart2Loading(false);
                                }, 200);

                                return;

                                console.log(
                                  "chart2 check " + x.name,
                                  e.target.checked
                                );
                                var dummy = chart2;

                                const selected = chart2.filter((x) => x.show);

                                console.log("chart1 selcted", selected.length);

                                if (selected.length == 3 && e.target.checked) {
                                  console.log("chart1 selcted is 3");

                                  var newSelected = [];
                                  if (chart2Selected.length == 0) {
                                    newSelected = [i];
                                  } else if (chart2Selected.length == 1) {
                                    newSelected = [chart2Selected[0], i];
                                  } else {
                                    dummy[chart2Selected[0]].show = false;
                                    newSelected = [chart2Selected[1], i];
                                  }

                                  setChart2Selected(newSelected);
                                  console.log(
                                    "chart2 new selected",
                                    JSON.stringify(newSelected)
                                  );

                                  chart2.map((x, i) => {
                                    dummy[i].show =
                                      i == 0 || newSelected.includes(i);
                                  });
                                } else {
                                  var newSelected = [];
                                  selected.map((s, i) => {
                                    if (i != 0) {
                                      newSelected.push(
                                        chart2.findIndex(
                                          (x) => x.field == s.field
                                        )
                                      );
                                    }
                                  });
                                  setChart2Selected(newSelected);
                                }

                                setChart2Loading(true);

                                dummy[i].show = e.target.checked;

                                //console.log("chart1 newData ",JSON.stringify(dummy))
                                setChart2(dummy);

                                setTimeout(() => {
                                  setChart2Loading(false);
                                }, 200);
                              }}
                              type={"checkbox"}
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>

      {/* <Card>
      
      
      <Card.Body><Tabs
        defaultActiveKey={activeToggle}
        id="uncontrolled-tab-example"
        className="mb-3"
        activeKey={activeToggle}
        onSelect={(k) => setActiveToggle(k)}
        justify
      >
        <Tab eventKey="data" title="data">
        
        </Tab>
       
        
      </Tabs>
      </Card.Body>
      </Card> */}
    </>
  );
}

export default DeviceDetails;
