import React, {  useRef,useState,useEffect ,Fragment } from "react";
import { Table, Pagination, Badge,Dropdown,Spinner,Modal } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch,useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from '../../../services/AxiosInstance';
import swal from "sweetalert";

import { format } from "date-fns";

import styled from 'styled-components';

import {
	getProfile,
   profileAction
 } from '../../../store/actions/AuthActions';



//const Orders = () => {
const Packs = ({ props, history }) => {

   const dispatch = useDispatch();
   const [packs, setPacks] = useState([]);
   const [loading, setLoading] = useState(false);

   const [orderCompleted, setOrderCompleted] = useState(false);

   const profile = useSelector((state) => state.auth.profile);

   const PackComponent = styled.div`
   
   .price-sec {
      box-sizing: border-box;
      padding: 20px 0px;
    }
    .price-sec .ptables-head {
      font-family: "Domine", serif;
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.3);
      padding: 30px 0;
      margin: 0px 0px 100px 0px;
      border-radius: 3px;
      background: linear-gradient(25deg, #feae3f 15%, transparent 0%),
        linear-gradient(-25deg, #f321d7 15%, transparent 0%),
        linear-gradient(-150deg, #64b5f6 15%, transparent 0%),
        linear-gradient(150deg, #f47 15%, transparent 0%);
    }
    @media all and (max-width: 600px) {
      .ptables-head h1 {
        font-size: 30px;
      }
    }
    
    .price-sec .price-table {
      margin: 5px 0px;
    }
    .price-sec .price-table .card {
      position: relative;
      max-width: 300px;
      height: auto;
      background: linear-gradient(-45deg, #fe0847, #feae3f);
      border-radius: 15px;
      margin: 0 auto;
      padding: 20px 10px;
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
      transition: 0.5s;
      overflow: hidden;
    }
    .price-sec .price-table .card:hover {
      transform: scale(1.1);
    }

    .starter .card, .starter .card .title i{
      background: linear-gradient(-45deg, #f403d1, #64b5f6);
    }
    
    .price-table:nth-child(1) .card,
    .price-table:nth-child(1) .card .title i {
      background: linear-gradient(-45deg, #f403d1, #64b5f6);
    }

    .price-table:nth-child(5) .card,
    .price-table:nth-child(5) .card .title i {
      background: linear-gradient(-45deg, #f403d1, #64b5f6);
    }


    .business .card, .business .card .title i{
      background: linear-gradient(-45deg, #fe6c61, #f321d7);
    }

    .price-table:nth-child(2) .card,
    .price-table:nth-child(2) .card .title i {
      background: linear-gradient(-45deg, #fe6c61, #f321d7);
    }

    .price-table:nth-child(6) .card,
    .price-table:nth-child(6) .card .title i {
      background: linear-gradient(-45deg, #fe6c61, #f321d7);
    }
    

    .type-3 .card, .type-3 .card .title i{
      background: linear-gradient(-45deg, #24ff72, #9a4eff);
    }

    .price-table:nth-child(3) .card,
    .price-table:nth-child(3) .card .title i {
      background: linear-gradient(-45deg, #24ff72, #9a4eff);
    }

    .price-table:nth-child(7) .card,
    .price-table:nth-child(7) .card .title i {
      background: linear-gradient(-45deg, #24ff72, #9a4eff);
    }

    .price-table .card:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: rgba(255, 255, 255, 0.1);
      z-index: 1;
      transform: skewY(-5deg) scale(1.5);
    }
    .price-table .title i {
      color: #fff;
      font-size: 40px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      text-align: center;
      line-height: 80px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    }
    .price-table .title h2 {
      position: relative;
      margin: 20px 0 0;
      padding: 0;
      color: #fff;
      font-size: 20px;
      z-index: 2;
    }
    .price-table .price {
      position: relative;
      z-index: 2;
    }
    .price-table .price h4 {
      margin: 0;
      padding: 15px 0;
      color: #fff;
      font-size: 40px;
    }
    .option {
      position: relative;
      z-index: 2;
    }
    .option ul {
      margin: 0;
      padding: 0;
    }
    .option ul li {
      margin: 0 0 10px;
      padding: 0px 15px;
      list-style: none;
      color: #fff;
      font-size: 16px;
    }
    
    .card button {
      position: relative;
      z-index: 2;
      background: #fff;
      color: #000;
      display: block;
      text-align: center;
      margin: 20px auto 0;
      font-size: 16px;
      cursor: pointer;
      text-decoration: none;
      border-radius: 40px;
      cursor:pointer;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }
    .card button:hover {
      text-decoration: none;
    }

      `;
   

   useEffect(() => {
      dispatch(getProfile());
		loadPacks();
	}, [dispatch]);


   const loadPacks = async () => {

      const { data } = await axiosInstance.get(
         "packs"
       );
 
       if(data.status){
         setPacks(data.packs);
       }
   }

   async function loadProfile(){
		
		try {
			
			const { data } = await axiosInstance.get(
        		"company/profile"
      		);


	  console.log("profile data - "+JSON.stringify(data));

		if(data.status === false){
			
		}else{
			//setUser(data.user);
			dispatch(profileAction(data.profile));
			console.log("profile data - "+JSON.stringify(data));
		}
		
		}catch (error) {
			

		}
	
	  }


   const placeOrder = async (id) => {

      console.log("placeOrder - "+id);
  
      const config = {
        header: {
          "Content-Type": "application/json",
        },
       };
  
        try {
  
        //axios.post("/api/auth/signIn",fd,config);
        const { data } = await axiosInstance.post(
          "orders",
            {
               pack_id:id
            },
          config
        );
  
          console.log("placeOrder response- "+data);
  
        if(data.status){
           //toast.error(data.message);
              displayRazorpay(data.result,data.razorpay_key);
              console.log("razoypayKey - "+data.razorpay_key);
        }
        
  
       } catch (error) {
  
        }
  
    }


    const razorpayVerification = async (razorpay_payment_id,razorpay_order_id,razorpay_signature) => {

      console.log("razorpayVerification start");
  const config = {
    header: {
      "Content-Type": "application/json",
    },
   };

  try {
    //axios.post("/api/auth/signIn",fd,config);
    const { data } = await axiosInstance.post(
      "orders/razorpay-verification",
      { razorpay_payment_id,razorpay_order_id,razorpay_signature },
      config
    );

      console.log("razorpayVerification response - "+data);

      if(data.status){
          //history.push('/thank-you/')
          setOrderCompleted(true);
          dispatch(loadProfile());
      }else{
          console.log("verify payment failed");
      }

  }catch(e){

  }


}

function loadScript(src){
   return new Promise((resolve) => {
     const script = document.createElement('script');
     script.src = src;
     script.onload = () => {
       resolve(true)
     }
     script.onError = () => {
       resolve(false)
     }
     document.body.appendChild(script);
   })
 }

 async function displayRazorpay(order,razoypayKey){
  
   console.log("displayRazorpay loading true");
   const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

   if(!res){
     console.log("Razorpay SDK fail to load. Are you online?")
     return;
   }

   const options = {
     "key": razoypayKey, // Enter the Key ID generated from the Dashboard
     "amount": order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
     "currency": order.currency,
     "name": "pvc2print",
     "description": "Order #"+order.receipt,
     "image": "https://pvc2print.s3.ap-south-1.amazonaws.com/logo.png",
     "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
     
     "prefill": {
         "name": profile?profile.name:"", //order.name,
         "email":profile?profile.email:"",
         "contact":profile?profile.phone:""
     },
     // "notes": {
     //     "address": "Razorpay Corporate Office"
     // },

     "handler": function (response){
       console.log(response.razorpay_payment_id);
       console.log(response.razorpay_order_id);
       console.log(response.razorpay_signature)
       
       razorpayVerification(response.razorpay_payment_id,response.razorpay_order_id,response.razorpay_signature);
     
   },
     "theme": {
         "color": "#3399cc"
     }
 };

 var razorpay = new window.Razorpay(options);

 razorpay.on('payment.failed', function (response){
   alert(response.error.code);
   alert(response.error.description);
   alert(response.error.source);
   alert(response.error.step);
   alert(response.error.reason);
   alert(response.error.metadata.order_id);
   alert(response.error.metadata.payment_id);
});

 razorpay.open();

 //setOrderId(order.receipt);
 //setProgress(false);

 }
  
   
   return (

      <>

      
      <Modal className="modal fade"  show={orderCompleted} onHide={setOrderCompleted} >
      <div className="" role="document">
         <div className="">
            <form >
               <div className="modal-header">
                  <h4 className="modal-title fs-20">Order Completed</h4>
                  <button type="button" className="close" onClick={()=> setOrderCompleted(false)}><span>×</span></button>
               </div>
               <div className="modal-body">
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                     <div className="add-contact-content">

                     <section class="hero">
                        <div class="container">
                              <div class="hero-inner">
                     <div class="hero-copy">
                                    <h1 class="hero-title mt-0">Thank You!</h1>
                                    <p class="hero-paragraph">Your order was completed successfully.<br/>Your counter is added in your account.</p>
                     </div>
                     </div>
                     </div>
                  </section>
                     
                        
                     </div>
                  </div>
               </div>
               
            </form>
                   
         </div>
      </div>
   </Modal>




      <Fragment>
         {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

      <div className="col-12">
         <div className="card">
           
            <div className="card-header">
               <h4 className="card-title mr-auto">Packs</h4>
            </div>

            <div className="card-body">

               {loading || packs.length == 0 ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:(
       
                  <PackComponent>
                     <div className="price-sec">
                     <div className="row">

                     {packs.map((pack, i) => (
                     <div class={"col-sm-3 mb-4 price-table ."+pack.type}>
                        <div class="card text-center">
                            <div class="title">
                                <i class="fa fa-print"></i>
                                <h2>{pack.type}<br/>{pack.name}</h2>
                            </div>
                            <div class="price">
                                <h4><sup>₹</sup>{pack.salePrice}</h4>
                            </div>
                            <div class="option">
                                <ul>
                                    <li><i class="fa fa-check"></i>{pack.counter} Counters</li>
                                    
                                </ul>
                            </div>
                            <button className="btn" onClick={(e)=>placeOrder(pack.id)}>Order Now</button>
                        </div>
                    </div>))}


                     </div>
                     </div>

                  </PackComponent>
       
              )}
       


             
         </div>
      </div>
      </div>

      </Fragment>

      </>
   );
};

export default Packs;
