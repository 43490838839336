import React, { useRef, useState, useEffect, Fragment } from "react";
import PhoneInput from "react-phone-number-input";
import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
  Button,
  Row,
  Col,
  Form,
  ListGroup,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { getProfile } from "../../../store/actions/AuthActions";

import messageTypeList from "../../../utils/message-type";

import { CSVLink } from "react-csv";

import DTable from "../Table";
import { batch } from "react-redux";
import { be } from "date-fns/locale";

//const Orders = () => {
const Messages = ({ props, history }) => {
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);

  const [loading, setLoading] = useState(false);
  const csvInstance = useRef(null);

  const profile = useSelector((state) => state.auth.profile);

  const [activePage, setActivePage] = useState(0);
  let errorsObj = { instance: "", phone: "", message: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [xlsData, setXlsData] = useState([]);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  //modal
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [activeCompany, SetActiveCompany] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
 

  //search
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadCompanies();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    if (
      xlsData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setXlsData([]);
      });
    }
  }, [xlsData]);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadCompanies();
  }, [page, pageSize]);

  // Data Table End

  useEffect(() => {
    dispatch(getProfile());
    loadCompanies();
  }, [dispatch]);

  useEffect(() => {
    if (!showCompanyModal) {
      resetForm();
    }
  }, [showCompanyModal]);

  useEffect(() => {
    if (companies) {
      setLoading(false);
    }
  }, [companies]);

  const loadCompanies = async () => {
    const { data } = await axiosInstance.post("admin/users", {
      role:2,
      page: page, 
      limit: pageSize,
      search: search,
    });

    if (data.status) {
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
      setCompanies(data.users);
    }
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const initXlsData = async () => {
    var dummy = [];

    const { data } = await axiosInstance.post("admin/companies");

    if (data.status) {
      console.log("total messages - " + data.companies.length);
      await asyncForEach(data.companies, async (d, i) => {
        //companies.map((data, i) => {
        dummy.push({
          sn_no: i + 1,
          id:d.id,
          name:d.name,
          email:d.email,
          phone:d.phone,
          created_at: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
        });
      });

      setXlsData(dummy);
    }
  };

  const columns = [
    {
      Header: "No",
      accessor: "",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "ID",
      accessor: "id",
      sort: false,
    },

    {
      Header: "Name",
      accessor: "name",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return <Link to={"/company/"+d.id} >{d.name}</Link>;
        
      },
    },
    {
      Header: "Email",
      accessor: "email",
      sort: false,
    },
    {
      Header: "Phone",
      accessor: "phone",
      sort: false,
    },

    {
      Header: "CREATED_AT",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={(e) => onCompanyEdit(d)}>
                Edit
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) => {
                  swal({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover this Company Record!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      onCompanyDelete(d);
                    }
                  });
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const onCompanySubmit = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    }

    if (phone === "") {
      error = true;
      errorObj.phone = "Phone is Required";
    }

    

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          `admin/${isEdit ? "update" : "add"}-user`,
          {
           
            id: activeCompany?activeCompany.id:"",
            name: name,
            phone: phone,
            email: email,
            role:2
          }
        );

        if (data.status === false) {
          //toast.error(data.message);
          swal(`${isEdit ? "Update" : "Add"} Company`, data.message, "error");
        } else {
          swal(`${isEdit ? "Update" : "Add"} Company`, data.message, "success");
          resetForm();

          setTimeout(() => {
            loadCompanies();
          }, 500);
        }
      } catch (error) {
        swal(`${isEdit ? "Update" : "Add"} Company`, error.message, "error");
      }
    }
  };

  const onCompanyEdit = async (company) => {
    SetActiveCompany(company);
    setIsEdit(true);

    setName(company.name);
    setEmail(company.email);
    setPhone(company.phone);
    setShowCompanyModal(true);
  };

  const onCompanyDelete = async (company) => {
    try {
      const { data } = await axiosInstance.post(`admin/remove-user`, {
       id: company.id,
      });

      if (data.status === false) {
        //toast.error(data.message);
        swal(`Remove Company`, data.message, "error");
      } else {
        swal(`Remove Company`, data.message, "success");
        resetForm();

        setTimeout(() => {
          loadCompanies();
        }, 500);
      }
    } catch (error) {
      swal(`Remove Company`, error.message, "error");
    }
  };

  const resetForm = async () => {
    setName("");
    setPhone("");
    setEmail("");
    setIsEdit(false);
    SetActiveCompany(null);
    setShowCompanyModal(false);
  };

  return (
    <>
      <Modal
        size={"md"}
        className="modal fade"
        show={showCompanyModal}
        onHide={setShowCompanyModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onCompanySubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  {!isEdit ? "Add" : "Update"} Company
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowCompanyModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                   
                    <div className="form-row mb-3">
                      <div className="form-group col-md-12">
                        <label className="required-field">Name</label>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter Name"
                          className="form-control"
                         
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>

                      <div className="form-group col-md-12">
                        <label className="required-field">Email</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter Email"
                          className="form-control"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">{errors.email}</div>
                        )}
                      </div>

                      <div className="form-group col-md-12">
                        <label className="required-field">Phone Number</label>
                        <PhoneInput
                        placeholder="Enter phone number"
                        value={phone}
                        defaultCountry="IN"
                        onChange={setPhone}
                      />
                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setShowCompanyModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"> </i>
                  Close
                </button>

                <button type="submit" className="btn btn-primary">
                  {isEdit ? "Save" : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div class="mr-auto">
                <h4 className="card-title">All Companies</h4>
              </div>

              <div class="col-md-6 mb-2">
                <div className="input-group search-area mr-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (searchTimeout) {
                        clearTimeout(searchTimeout);
                      }
                      loadCompanies();
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </form>
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>

              <Button
                className="btn btn-sm btn-primary mr-2"
                onClick={() => {
                  resetForm();
                  setShowCompanyModal(true);
                }}
              >
                Add Company
              </Button>

              {xlsData.length > 0 ? (
                <CSVLink
                  ref={csvInstance}
                  data={xlsData}
                  filename={"companies.csv"}
                  className="btn btn-sm btn-primary mr-2"
                  target="_blank"
                >
                  Export
                </CSVLink>
              ) : (
                <Button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => initXlsData()}
                >
                  Export
                </Button>
              )}
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : companies.length == 0 ? (
                <p>No Company Found</p>
              ) : (
                <DTable
                  columns={columns}
                  data={companies}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Messages;
