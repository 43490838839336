const profileTypeList = [
  {
    id:0,
    title:"Text",
    active:true
  },
  {
    id:1,
    title:"Text With Media",
    active:false
  },
  {
    id:2,
    title:"Quick Reply Button",
    active:true
  },
  {
    id:3,
    title:"Quick Reply Button With Media",
    active:false
  },
  {
    id:4,
    title:"Call To Action Button",
    active:false
  },
  {
    id:5,
    title:"Call To Action Button With Media",
    active:false
  },
  {
    id:6,
    title:"List/Menu",
    active:false
  }
  ];

  export default profileTypeList